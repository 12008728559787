import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import { Link } from 'gatsby'
import termsImg from '../../assets/images/terms-of-service.jpg'

const TermsOfUse = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Terms of Use" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Terms of Use" 
            />
            <section className="terms-of-service-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="terms-of-service-content">
                                <img src={termsImg} alt="tt" />
                                <p><i>These Terms of User were last updated on January 2024.</i></p>
                                <h3>1. Who We Are</h3>
                                <blockquote className="blockquote">
                                    <p>These Terms of Use govern your use of Neolytics AI products such as NeoGPT, provided by Neolytics, a South African company. They apply to your use of services offered by Neolytics, including associated software applications and websites (collectively referred to as “Services”). By using our Services, you enter into an agreement with Neolytics, pty(ltd).</p>
                                </blockquote>
                                <h3>2. Registration and Access</h3>
                                <p><b>Age Restriction: </b>You must be a minimum of 13 years old or meet the age requirement set by your country to consent to using the Services. If you are under 18, you must obtain permission from your parent or legal guardian to use the Services.</p>
                                <p><b>Registration: </b>To use our Services, you are required to provide truthful and thorough information during the account registration process. You are prohibited from disclosing your account credentials or allowing others to use your account, and you are accountable for all actions conducted under your account.</p>
                                <h3>3. Using our Services</h3>
                                <p><b>What you can do: </b>Subject to your compliance with these Terms, you may access and use our Services. In using our Services, you must comply with all applicable laws.</p>
                                <p><b>What you cannot do: </b>You may not use our Services for any illegal, harmful, or abusive activity. </p>
                                <p>For example, you may not:</p>
                                <ol>
                                    <li>Avoid using our Services in a manner that infringes upon, misappropriates, or violates the rights of any individual or entity.</li>
                                    <li>Refrain from modifying, copying, leasing, selling, or distributing any aspect of our Services.</li>
                                    <li>Do not attempt, or aid others in attempting, to reverse engineer, decompile, or uncover the source code or fundamental components of our Services, including models, algorithms, or systems, unless this restriction is prohibited by applicable law.</li>
                                    <li>Do not falsely represent that the generated Output originated from a human source when it did not.</li>
                                    <li>Do not disrupt or interfere with our Services, including bypassing rate limits, restrictions, or protective measures we have implemented.</li>
                                </ol>
                                <h3>4. Termination and suspension</h3>
                                <p><b>Termination:</b> You are free to stop using our Services at any time. We reserve the right to suspend or terminate your access to our Services or delete your account if we determine:</p>
                                <p><b>Appeals: </b>If you believe we have suspended or terminated your account in error, you can file an appeal with us by emailing us at <a href="mailto:info@neolytics.io">info@neolytics.io</a></p>
                                <h3>5. User Conduct</h3>
                                <p><b>Respectful Behavior:</b> You agree to conduct yourself in a respectful and lawful manner while using our Services. This includes refraining from harassing, threatening, or abusing other users.</p>
                                <p><b>Content Guidelines:</b> You are responsible for the content you create, upload, or share using our Services. Content must not be illegal, defamatory, obscene, or infringe upon the rights of others.</p>

                                <h3>6. Intellectual Property</h3>
                                <p><b>Ownership:</b> All intellectual property rights related to our Services, including but not limited to software, algorithms, models, and content, belong to Neolytics. You are granted a limited, non-exclusive, and non-transferable license to use the Services as permitted by these Terms.</p>
                                <p><b>Use of Output:</b> Output generated by our Services remains the intellectual property of Neolytics. You may use Output for personal or internal purposes but may not sell, distribute, or use it for commercial gain without our explicit permission.</p>

                                <h3>7. Privacy and Data Security</h3>
                                <p><b>Privacy Policy:</b> Your use of our Services is subject to our Privacy Policy, which outlines how we collect, use, and protect your personal information.</p>
                                <p><b>Data Security:</b> We implement reasonable security measures to protect your data but cannot guarantee absolute security. You are responsible for safeguarding your account credentials and notifying us of any unauthorized access or use of your account.</p>

                                <h3>8. Limitation of Liability</h3>
                                <p><b>No Warranties:</b> We provide our Services on an "as is" and "as available" basis without warranties of any kind, express or implied. We do not guarantee the accuracy, reliability, or suitability of the Services for your purposes.</p>
                                <p><b>Limitation of Liability:</b> Neolytics and its affiliates shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of or inability to use our Services, even if we have been advised of the possibility of such damages.</p>

                                <h3>9. Governing Law and Dispute Resolution</h3>
                                <p><b>Governing Law:</b> These Terms of Use are governed by the laws of South Africa. Any disputes arising from these Terms shall be resolved through arbitration in accordance with the rules of the South African Arbitration Association.</p>
                                <p><b>Severability:</b> If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall continue to be valid and enforceable to the fullest extent permitted by law.</p>

                                <h3>10. Contact Us</h3>
                                <p>If you have any questions, concerns, or feedback regarding these Terms of Use or our Services, please contact us at <a href="mailto:info@neolytics.io">info@neolytics.io</a>.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                        <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li className='active'>
                                            <Link to="/legal/terms-of-use">
                                                Terms of Use
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/legal/privacy-policy">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/legal/cookie-policy">
                                                Cookie Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/legal/other-policies">
                                                Other Policies
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="widget widget_tag_cloud">
                                    <h3 className="widget-title">Popular Tags</h3>

                                    <div className="tagcloud">
                                    <Link to="/about-us">What is Neolytics? <span className="tag-link-count"> (3)</span></Link>
                                    <Link to="/contact">how do I contact Neolytics? <span className="tag-link-count"> (3)</span></Link>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default TermsOfUse;